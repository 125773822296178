<template>
    
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{rowReg.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        
        <div class="content">
            <validation-observer ref="VForm">
            <b-form @submit.prevent="doSubmit(false)" class="card mt-3">
                <div class="card-header bg-white">
                    <h6 class="card-title font-weight-semibold">Form Triase Pasien</h6>
                </div>
                <div class="card-body">
                    <div class="wrap_line_heading">
                    <div class="data_prev_cont">
                        <!--
                        <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="card-body bg_card_blue">
                            <div class="row">
                                <div class="col-md-9">
                                <table class="table table-borderless table-sm text-uppercase">
                                    <tbody>
                                    <tr>
                                        <td width="33%">
                                        <div class="result_tab">
                                            <h4>No. Reg</h4>
                                            <p>{{rowReg.aur_reg_code||"-"}}</p>
                                        </div>
                                        </td>
                                        <td width="33%">
                                        <div class="result_tab">
                                            <h4>No. Rekam Medis</h4>
                                            <p>{{rowReg.ap_code||"-"}}</p>
                                        </div>
                                        </td>
                                        <td width="33%">
                                        <div class="result_tab">
                                            <h4>Nama Pasien</h4>
                                            <p>{{rowReg.ap_fullname||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                        <div class="result_tab">
                                            <h4>Tempat, Tanggal Lahir </h4>
                                            <p>{{rowReg.ap_pob||"-"}}, {{rowReg.ap_dob | moment("DD MMMM YYYY")}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Usia</h4>
                                            <p>{{rowReg.ap_usia_with_ket||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Jenis Kelamin</h4>
                                            <p>{{rowReg.ap_gender == 1 ? 'Laki-Laki':'Perempuan'}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        -->

                        <div class="form-row">
                        <div class="form-group col-md-5">
                            <label>Triase Pasien <small class="txt_mandatory">*</small></label>
                            <v-select :disabled="isDisabledPerawat" @input="inputData('triase')" id="triase" placeholder=" -- Pilih Triase Pasien -- " v-model="row.aut_triase"
                                :options="mUGDTriase" label="text" :clearable="true"
                                :reduce="v=>v.value">
                                
                                <template slot="selected-option" slot-scope="option">
                                    <span :class="`triase_${option.color} p-1 d-inline-block mr-2 border`"></span>    
                                    <span>{{option.text}}</span>                    
                                </template>
                                
                                <template slot="option" slot-scope="option">
                                    <span :class="`triase_${option.color} p-1 d-inline-block mr-2 border`"></span>
                                    <span>{{option.text}}</span>                    

                                </template>

                            </v-select>



                            <VValidate 
                                name="Triase Pasien" 
                                v-model="row.aut_triase" 
                                :rules="toValidate(mrValidation.aut_triase)"
                            />
                            
                            <span class="text-warning d-none" id="triase-typing">User Lain Sedang Mengisi ...</span>

                        </div>

                        <div class="form-group col-md-4">
                            <label>Transportasi yang digunakan <small class="txt_mandatory">*</small></label>
                            <v-select :disabled="isDisabledPerawat" @input="inputData('transportasi')" id="transportasi" placeholder=" -- Pilih Transportasi yang digunakan -- " v-model="row.aut_transportasi"
                                :options="mUGDTransport" label="text" :clearable="true"
                                :reduce="v=>v.value">
                            </v-select>
                            <VValidate 
                                name="Transportasi yang digunakan" 
                                v-model="row.aut_transportasi" 
                                :rules="toValidate(mrValidation.aut_transportasi)"
                            />
                            
                            <span class="text-warning d-none" id="transportasi-typing">User Lain Sedang Mengisi ...</span>

                        </div>

                        <div class="form-group col-md-3" id="otherTransport" v-if="row.aut_transportasi == 99">
                            <label>Sebutkan<small class="txt_mandatory">*</small></label>
                            <b-form-input :disabled="isDisabledPerawat" @input="inputData('sebutkan')" id="sebutkan" v-model="row.aut_transportasi_text"  type="text" class="form-control"
                            placeholder="Sebutkan" />
                            <VValidate 
                                name="Transportasi Lainnya" 
                                v-model="row.aut_transportasi_text" 
                                :rules="toValidate(mrValidation.aut_transportasi_text)"
                            />
                        
                            <span class="text-warning d-none" id="sebutkan-typing">User Lain Sedang Mengisi ...</span>

                        </div>
                        <div class="col-md-4" v-if="rowReg.aures_no_rujukan">
                            <div class="form-group">
                            <label for="">Nomor Rujukan<strong class="txt_mandatory"></strong></label>
                            <div class="input-group">
                                <input :disabled="isDisabledPerawat" type="text" class="form-control" :value="rowReg.aures_no_rujukan" readonly>
                                <div class="input-group-append"><a :href="$parent.uploader(rowReg.aures_surat_rujukan)" target="_blank" class="btn btn-sm border-info alpha-info text-info-800" data-popup="tooltip" title="Lihat Surat Rujukan"><i class="icon-file-eye"></i></a></div>
                            </div>
                            </div>
                        </div>

                        </div>
                        
                        <div class="form-row">
                            <div class="form-group col-md-5">
                                <label>Penempatan <small class="txt_mandatory">*</small></label>
                                <v-select :disabled="isDisabledPerawat" @input="inputData('penempatan')" id="penempatan" placeholder=" -- Pilih Penempatan -- " v-model="row.aut_penempatan"
                                    :options="Config.mr.triasePenempatan" label="text" :clearable="true"
                                    :reduce="v=>v.value">
                                </v-select>           
                                <VValidate 
                                    name="Penempatan" 
                                    v-model="row.aut_penempatan" 
                                    :rules="toValidate(mrValidation.aut_penempatan)"
                                />                
                                
                                <span class="text-warning d-none" id="penempatan-typing">User Lain Sedang Mengisi ...</span>

                            </div>

                            <div class="form-group col-md-4">
                                <label>Skrining Kebutuhan pasien <small class="txt_mandatory">*</small></label>
                                <v-select :disabled="isDisabledPerawat" @input="inputData('skrining')" id="skrining" placeholder=" -- Pilih Skrining -- " v-model="row.aut_skrining_kebutuhan"
                                    :options="Config.mr.triaseSkrining" label="text" :clearable="true"
                                    :reduce="v=>v.value">
                                </v-select>      
                                <VValidate 
                                    name="Skrining Kebutuhan pasien" 
                                    v-model="row.aut_skrining_kebutuhan" 
                                    :rules="toValidate(mrValidation.aut_skrining_kebutuhan)"
                                />                        
                                
                                <span class="text-warning d-none" id="skrining-typing">User Lain Sedang Mengisi ...</span>
                            </div>
                        </div>
                        
                        <div class="form-row">

                        <div class="form-group col-md-3">
                            <label>Nama Pengantar Pasien</label>
                            <b-form-input :disabled="isDisabledPerawat" @input="inputData('nama-pengantar')" id="nama-pengantar" v-model="row.aut_pengantar_pasien" type="text" class="form-control"
                            placeholder="Nama Pengantar Pasien" />
                            <VValidate 
                                name="Pengantar Pasien" 
                                v-model="row.aut_pengantar_pasien" 
                                :rules="toValidate(mrValidation.aut_pengantar_pasien)"
                            />
                            <span class="text-warning d-none" id="nama-pengantar-typing">User Lain Sedang Mengisi ...</span>
                        </div>

                        <div class="form-group col-md-4">
                            <label>No Handphone</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon2">+62</span>
                                </div>
                                    <b-form-input :disabled="isDisabledPerawat" @input="inputData('no-handphone')" id="no-handphone"  :formatter="$parent.numberOnly"  v-model="row.aut_no_hp" type="text" />
                                </div>
                                
                                <VValidate 
                                    name="No Handphone" 
                                    v-model="row.aut_no_hp" 
                                    :rules="toValidate(mrValidation.aut_no_hp)"
                                />
                                <span class="text-warning d-none" id="no-handphone-typing">User Lain Sedang Mengisi ...</span>
                            </div><br/>
                        
                        </div>
                        <hr>

                        <div class="row">
                        <div class="col-md-12">
                        <h6 class="font-weight-semibold">Keterangan Emergency Penjaminan</h6>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                            <label for="diagnosisEmergency">Diagnosis<small class="txt_mandatory">*</small></label>
                            <b-form-textarea :disabled="isDisabledPerawat" @input="inputData('diagnosis')" id="diagnosis" v-model="row.aut_diagnosa" placeholder="e.g. nyeri di paha kanan, ngilu di bagian lutut" rows="4" />
                            <VValidate 
                                name="Diagnosis" 
                                v-model="row.aut_diagnosa" 
                                :rules="toValidate(mrValidation.aut_diagnosa)"
                            />
                            <span class="text-warning d-none" id="diagnosis-typing">User Lain Sedang Mengisi ...</span>

                            </div>
                            <div class="form-group">
                            <label for="">Pasien Mendapatkan Surat Emergency?<small class="txt_mandatory">*</small></label>
                            <div>
                                <b-form-radio-group
                                    :disabled="isDisabledPerawat"
                                    @change="inputData('surat-emergency')" id="surat-emergency" 
                                    :options="Config.mr.yesNoOptV2"
                                    v-model="row.aut_is_surat_emergency"
                                />
                                <VValidate 
                                    name="Surat Emergency" 
                                    v-model="row.aut_is_surat_emergency" 
                                    :rules="toValidate(mrValidation.aut_is_surat_emergency)"
                                />
                                <span class="text-warning d-none" id="surat-emergency-typing">User Lain Yang SedangMengisik ...</span>

                                
                            </div>
                            </div>
                            <!-- Alert muncul jika triase diisi oleh Perawat -->
                            <div class="form-group ml-4" v-if="row.aut_is_surat_emergency == 'Y' && $parent.user.levelId == $parent.uPerawatUGD">
                                <b-form-checkbox @input="inputData('arahan-dokter')" id="arahan-dokter" v-model="row.aut_is_arahan_dokter" 
                                :disabled="isDisabledPerawat" 
                                value="Y"
                                unchecked-value="N" class="form-check-input-styled p-0" name="edukasi">
                                Dengan memilih "Ya", Anda mengkonfirmasi bahwa pemberian Surat Emergency sesuai dengan arahan dari Dokter
                                </b-form-checkbox>

                                <span class="text-warning d-none" id="arahan-dokter-typing">User Lain Sedang Mengisi ...</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                
                <div class="card border shadow-0" v-if="row.aut_created_date">
                    <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="addInfoTindakan">Keterangan Perubahan</label>
                            <b-textarea v-model="row.aut_notes" 
                                :disabled="isDisabledPerawat" rows="6" class="form-control"
                                placeholder="Keterangan Perubahan">
                            </b-textarea>


                            <VValidate :name="`Keterangan Perubahan`" v-model="row.aut_notes"
                                :rules="{required: 1, min: 2, max:256}" />
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="text-right">
                    <a href="javascript:;" @click="$router.back()" class="btn btn-link mr-2">Kembali</a>
                    <button type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                    </div>
                </div>
            </b-form>
            </validation-observer>
        </div>
    </div>

</template>


<script>
    import $ from 'jquery'
    const _ = global._
    import Config from '@/libs/Config'
    import Gen from '@/libs/Gen.js'
    const moment = require('moment')
    
    export default{
        props:{
            row:Object,
            mrValidation: Object,
            rowPasien: Object,  
            rowReg: Object, 
        },
        data(){
            return {
                mUGDTriase : [],
                mUGDTransport : []
            }
        },
        mounted() {
            this.sockets.subscribe('disp_refresh_data_all_ugd', function(data) {
                if(data.aur_id == this.$route.query.regId && 
                data.level == this.$parent.uPerawatUGD
                && this.$parent.uDokterUGD == this.$parent.user.levelId){
                    return this.$swal({
                        title: 'Perawat sudah mensubmit data',
                        text: 'Silakan Lihat kajian atau setujui kajian',
                        icon: 'warning',         
                        confirmButtonText: 'Setujui Data',
                        cancelButtonText: 'Lihat Kajian',
                        showCancelButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        if (result.isConfirmed) {
                            this.doSubmit(true)
                        }
                    })
                }

                if(data.aur_id == this.$route.query.regId && 
                data.level == this.$parent.uDokterUGD
                && this.$parent.uPerawatUGD == this.$parent.user.levelId){
                    return this.$swal({
                        title: 'Dokter sudah mensubmit data',
                        text: 'Silakan lihat triase pasien lainnya',
                        icon: 'warning',         
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        if (result.value) {
                            this.$router.push({ name: 'UGDTriase' }).catch(() => {})
                        }
                    })
                }
            })
            
            this.$socket.emit('new_reg_id',this.$route.query.regId)
        
            this.sockets.subscribe('show_triase_ugd', function(data) {
              
                if(this.$parent.user.levelId != data.usersLevel){
                    this.row = data
                    if (data.element == 'triase') {
                        this.row.aut_triase = data.aut_triase
                    }
                    if (data.element == 'transportasi') {
                        this.row.aut_transportasi = data.aut_transportasi
                    }
                    if (data.element == 'nama-pengantar') {
                        this.row.aut_pengantar_pasien = data.aut_pengantar_pasien
                    }
                    if (data.element == 'no-handphone') {
                        this.row.aut_no_hp = data.aut_no_hp
                    }
                    if (data.element == 'diagnosa') {
                        this.row.aut_diagnosa = data.aut_diagnosa
                    }
                    if (data.element == 'surat-emergency') {
                        this.row.aut_is_surat_emergency = data.aut_is_surat_emergency
                    }
                    if (data.element == 'penempatan') {
                        this.row.aut_penempatan = data.aut_penempatan
                    }
                    if (data.element == 'skrining') {
                        this.row.aut_skrining_kebutuhan = data.aut_skrining_kebutuhan
                    }

                    let elem = document.getElementById(data.element)
                    if(elem){
                        let elemtype = document.getElementById(data.element+"-typing")
                        elem.disabled = true
                        elemtype.classList.remove('d-none')        
                        setTimeout(()=>{
                            elem.disabled = false      
                            elemtype.classList.add('d-none')              
                        },1000)
                    }
                }
            })

            setTimeout(()=>{
                this.apiGetMaster()
            },250)
            
            setTimeout(()=>{
                this.initSticky()
            },1000)
        
        },
        computed:{
            isDisabledPerawat(){
                // return false
                return (this.row.last_users == this.$parent.uDokterUGD) && (this.$parent.user.levelId == this.$parent.uPerawatUGD)
            },
            Config(){ return Config },
        },
        methods: {
            initSticky(){
                const PageHeaderSticky = document.querySelector('.page-header-sticky')
                if(PageHeaderSticky){    
                    const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                    if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                        PageHeaderToggle.addEventListener('click', function(e){
                        // e.preventDefault()
                        PageHeaderSticky.classList.toggle('page-header-sticky-open')
                        if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                            PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                            PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                            PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                        }else{
                            PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                            PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                            PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                        }
                        })    
                    } 
                }
            },
            doSubmit(isResubmit=false) {
                this.$refs['VForm'].validate().then(success => {
                    

                    if(this.row.aut_is_surat_emergency == 'Y' && this.row.aut_is_arahan_dokter != 'Y' && this.$parent.user.levelId == this.$parent.uPerawatUGD){
                        return this.$swal({
                            icon: 'error',
                            title: 'Anda belum mengkonfirmasi bahwa Surat Emergency sudah sesuai arahan Dokter.'
                        })
                    }

                    if (!success) {
                        return this.$swal({
                            icon: 'error',
                            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                        })
                    }
                    if (success) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Apakah Anda Yakin akan menyimpan data ini?',
                            showCancelButton: true,
                            confirmButtonText: 'Ya',
                            cancelButtonText: 'Tidak, kembali'
                        }).then(result => {
                            if (result.value) {
                                let data = this.row
                                data.aut_aur_id = this.rowReg.aur_id
                                data.type = this.$route.params.pageSlug == 'add' ? 'add' : 'update'
                                data.isResubmit = isResubmit

                                this.$socket.emit('refresh_data_all_ugd',{
                                    aur_id : this.rowReg.aur_id,
                                    level: this.$parent.user.levelId
                                })
                           
                                this.$parent.loadingOverlay = true
                                Gen.apiRest(
                                    "/do/" + this.$parent.modulePage, {
                                        data: data
                                    },
                                "POST"
                                ).then(res => {
                                    this.$parent.loadingOverlay = false
                                    let resp = res.data
                                    console.log(resp)
                                    resp.statusType = 200
                                    this.$swal({
                                        title: resp.message,
                                        icon: resp.status,
                                        confirmButtonText: 'Ok',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false
                                    }).then(result => {
                                        if (result.value) {
                                            this.$router.push({
                                                name: 'UGDTriase'
                                            }).catch(() => {})
                                        }
                                    })
                                }).catch(err => {
                                    this.$parent.loadingOverlay = false
                                    if (err) {
                                        if(err.response?.data?.message == "Pasien Sudah Dilakukan Triase"){
                                            return this.$swal({
                                                title: 'Data ini Sudah Dilakukan Triase',
                                                text: 'Silakan Kembali, dan lihat data Anda',
                                                icon: 'error',
                                                confirmButtonText: 'Ok',
                                                allowOutsideClick: false,
                                                allowEscapeKey: false
                                            }).then(result => {
                                                if (result.value) {
                                                    this.$router.push({
                                                        name: 'UGDTriase'
                                                    }).catch(() => {})
                                                }
                                            })
                                        }
                                        if(err.response?.data?.message == "Dokter Sudah Mensubmit Data"){
                                            this.$router.push({name: this.$route.name, 
                                                params: {pageSlug: this.$route.params.pageSlug}, 
                                                query: {regId: this.$route.query.regId, isDetail: true}
                                            }).catch(()=>{})
                                        }

                                        err.statusType = err.status
                                        err.status = "error"
                                        err.title = err.response?.data?.title
                                        err.message = err.response?.data?.message
                                        err.messageError = err.message
                                            

                                    }
                                    this.$parent.doSetAlertForm(err)
                                })
                            }
                        })
                    }
                })
            },
            toValidate(val){
                return {...val}
            },
            apiGetMaster(){
                Gen.apiRest(
                    "/get/"+this.$parent.modulePage+'/master' 
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },  
            
            inputData(element){
                // untuk form kolaborasi

                let data = this.row
                data.to = this.$route.query.regId
                data.usersLevel = this.$parent.user.levelId
                data.element = element

                this.$socket.emit('triase_ugd',data)
            },

            autoSave: _.debounce(function (data) {
                data.type = 'auto-save'
                Gen.apiRest(
                    "/do/"+this.$parent.modulePage,
                    {data:data}, 
                    "POST"
                )
            },1000), 
        },
        watch: {
            row: {
                handler(v) {
                    this.autoSave(v)
                },
                deep: true
            },
        },
        sockets: {
            connect() {
                this.$socket.emit('new_reg_id',this.$route.query.regId)
                this.isConnected = true
            },

            disconnect() {
                this.isConnected = false
            },

            messageChannel(data) {
                this.socketMessage = data
            },
        },
    }
</script>