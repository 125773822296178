<template>    
    <div class="content-wrapper">
        <div v-if="isList" class="content pt-0">
            <bo-page-title></bo-page-title>
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0">
                                            <div class="input-group">
                                                <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :opens="'right'"
                                                    :ranges="datePickerConfig.ranges"
                                                    @update="updateValues"
                                                >
                                                    <template v-slot:input="picker">
                                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                                </date-range-picker>
                                                <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--
                        <div class="col-md-auto col-lg-7">
                            <div class="form-row justify-content-end">
                                <div class="col-md-5">
                                    <div class="lbl_status_pasien bg_red">
                                        <h3>
                                            <i class="icon-users2"></i>
                                            {{pasienBaru}} PASIEN BARU
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="lbl_status_pasien bg_green">
                                        <h3>
                                            <i class="icon-user-check"></i>
                                            {{pasienLama}} PASIEN LAMA
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->

                        <div class="col-md">
                            <div class="row g-0 justify-content-end">
                            <div class="col">
                                <div class="d-flex text-center flex-column align-items-center p-1 border-top border-bottom border-left text-warning-600">
                                <span class="font-weight-semibold">{{belumTriase}} Pasien</span>
                                <small>Belum Triase</small>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-flex text-center flex-column align-items-center p-1 border-top border-bottom triase_red text-white ">
                                <span class="font-weight-semibold ">{{Esi1}} Pasien</span>
                                <small class="text-white">ESI Level 1-2</small>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-flex text-center flex-column align-items-center p-1 border-top border-bottom triase_yellow text-white ">
                                <span class="font-weight-semibold ">{{Esi3}} Pasien</span>
                                <small>ESI Level 3-4</small>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-flex text-center flex-column align-items-center p-1 border-top border-bottom triase_green text-white ">
                                <span class="font-weight-semibold ">{{Esi5}} Pasien</span>
                                <small>ESI Level 5</small>
                                </div>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-auto">
                                            <div class="form-group mb-0">
                                                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                            </div>
                                        </div>

                                        <!--                       
                                        <div class="col-md-3">
                                            <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_pasien"  :options="Config.mr.statusPasien" label="text" :reduce="v=>v.value"></v-select>
                                        </div>
                                        -->
                                        
                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Rujukan -- " @input="doFill" v-model="filter.status_rujukan"  :options="Config.mr.statusRujukanUGD" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>

                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>     

                         
                
                <div class="tab-content">
                    <div :class="'tab-pane fade show active'">
                        <div class="table-responsive">
                            <table
                                class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                                <thead>
                                    <tr>
                                        <th>NO.</th>
                                        <th>NO. KEDATANGAN</th>
                                        <th>TANGGAL DAFTAR</th>
                                        <th>NAMA/NO KTP</th>
                                        <th>TRIASE</th>
                                        <th>DOKTER JAGA UGD</th>                                
                                        <th>NO RM</th>
                                        <th>RUJUKAN & CARA BAYAR</th>
                                        <th>TANGGAL LAHIR</th>
                                        <th>USIA</th>
                                        <th>STATUS</th>
                                        <th>DIBUAT OLEH</th>
                                        <th>AKSI</th>
                                    </tr>
                                </thead>
                                
                                <tbody v-if="(dataList||[]).length">
                                    <tr v-for="(v,k) in (dataList||[])" :key="k">
                                        <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                        <td>
                                            <div class="text-success-700 font-weight-semibold">{{v.aur_reg_code||"-"}}</div>
                                            <span v-if="v.aures_type == 'Q'" :class="`badge bg-success`">Reservasi Cepat</span>
                                            <span v-else :class="`badge bg-info`">Reservasi Normal</span>
                                        </td>
                                        
                                        <td>
                                            <div>{{v.aur_reg_date | moment("DD MMMM YYYY, HH:mm")}}</div>
                                        </td>

                                        <td>
                                            <a href="javascript:;" v-if="(v.aur_have_triase == 'N') || (v.aur_have_triase == 'Y' && otherConditional(v) && (v.aut_created_by_level == uPerawatUGD) || (v.aut_updated_by_level == uDokterUGD))" @click="toFormTriase(v.aur_aut_id,v.aur_id,v.aur_ap_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right data-popup="tooltip" title="Periksa Pasien" > {{uppercaseWord(v.ap_fullname)||"-"}}</a>

                                            
                                            <router-link href="javascript:;" :to="{name: $route.name, params: {pageSlug: v.aur_aut_id}, query: {regId: v.aur_id, isDetail: true}}" v-else class="font-weight-semibold border-bottom" v-b-tooltip.hover.right data-popup="tooltip" title="Lihat Data Pasien" > {{uppercaseWord(v.ap_fullname)||"-"}}</router-link>
                                            
                                            <div class="text-info">{{v.ap_nik||"-"}}</div>
                                            <span v-if="v.aur_is_emergency == 'Y'" :class="`badge bg-danger mt-2`">Emergency</span>
                                        </td>
                                        
                                        <template v-if="v.aur_triase_id">
                                            <td :class="'triase_'+v.color">
                                            <strong class="font-weight-semibold text-white">{{v.triase_type||"-"}}</strong>
                                            </td>
                                        </template>
                                        
                                        <template v-else>
                                        <td>
                                            <span class="font-weight-semibold text-warning-600">Belum Ada</span>
                                        </td>
                                        </template>
                                               
                                        <td>
                                            <div class="font-weight-semibold">{{v.bu_full_name||"-"}}</div>
                                        </td>
                                        <td>
                                            <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                            <small :class="v.ap_is_old_pasien == 'Y' ? 'font-weight-semibold text-danger' : 'font-weight-semibold text-primary'" style="font-size: 12px;">
                                            {{v.ap_is_old_pasien == 'Y' ? 'PASIEN LAMA' : 'PASIEN BARU'}}</small>
                                        </td>
                                        <td>
                                            <div>{{getConfigDynamic(Config.mr.statusUGDRujukan,v.aures_asal_rujukan)||"-"}}</div>
                                            <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusJaminan,v.aures_jaminan)||"-"}}</div>
                                        </td>
                                        <td>
                                            <div v-if="v.ap_dob" class="font-weight-semibold">
                                                {{v.ap_dob | moment("DD MMM YYYY")}}
                                            </div>
                                            <span v-else> - </span>
                                        </td>
                                        <td>
                                            <div class="font-weight-semibold">
                                                {{v.ap_usia_with_ket || "-"}}
                                            </div>
                                        </td>
                                        <td>
                                        
                                            <div class="badge badge-dark mb-1" v-if="v.aur_is_void == 'Y'">
                                                VOID
                                            </div>
                                            <template v-else>
                                                <div class="badge bg-warning mb-1" v-if="v.aur_have_triase == 'N'">
                                                    Perlu Triase
                                                </div>
                                                <div class="badge bg-success mb-1" v-if="v.aur_have_triase == 'Y'">
                                                    Triase Ditambahkan
                                                </div>
                                                <div class="badge bg-primary mb-1" v-if="v.aur_status != 1">
                                                    {{v.aus_name||"-"}}
                                                </div>
                                            </template>


                                        </td>
                                        <td>
                                            {{v.nama_inputter||"-"}}
                                        </td>
                                        <td>
                                            <template v-if="v.aur_is_void != 'Y'">
                                                <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                                v-if="moduleRole('view') && v.aur_have_triase == 'Y'"
                                                :to="{name: $route.name, params: {pageSlug: v.aur_aut_id}, query: {regId: v.aur_id, isDetail: true}}"
                                                data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" v.b- title="Lihat Detail Triase"><i class="icon-eye"></i></router-link>


                                                <!-- untuk perawat -->
                                                <template v-if="user.levelId == uPerawatUGD">
                                                    <a href="javascript:;"
                                                    @click="toFormTriase(v.aur_aut_id,v.aur_id,v.aur_ap_id)" v-if="
                                                    (v.aur_have_triase == 'Y' && otherConditional(v) && (v.aut_created_by_level != uDokterUGD) && (v.aut_updated_by_level != uDokterUGD))" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                                    data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Edit Data Triase"><i class="icon-pencil5"></i></a>
                                                </template>

                                                <!-- untuk dokter -->
                                                <template v-if="user.levelId == uDokterUGD">
                                                    <a href="javascript:;"
                                                    @click="toFormTriase(v.aur_aut_id,v.aur_id,v.aur_ap_id)" v-if="
                                                    (v.aur_have_triase == 'Y' && otherConditional(v))" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                                    data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Edit Data Triase"><i class="icon-pencil5"></i></a>
                                                </template>

                                                
                                                <a href="javascript:;" class="btn btn-icon rounded-round btn-sm alpha-info border-info" data-popup="tooltip" v-b-tooltip.hover title="Ajukan Perubahan Data" @click="requestEdit(v)" v-if="!v.aut_can_edit_by && !otherConditional(v)"><i class="icon-drag-left"></i></a>

                                                <a href="javascript:;" @click="toFormTriase(v.aur_aut_id,v.aur_id,v.aur_ap_id)" v-if="v.aur_have_triase == 'N'" class="btn btn-icon rounded-round btn-sm mr-1 alpha-warning border-warning"
                                                data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Tambah Data Triase"><i class="icon-stack-plus"></i></a>

                                            </template>
                                            <template v-else>
                                                <span> - </span>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="data.data.length == 0">
                                    <tr>
                                        <td colspan="99">
                                        <div class="text-center">
                                            <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                            <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="!data.data">   
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                            class="mb-0"
                            v-model="pageNo"
                            :per-page="data.per_page"
                            :total-rows="data.total"
                        />
                        </b-card-footer>
                        
                    </div>
                </div>

                <b-modal 
                    v-model="openPersetujuan"
                    :title="'Konfirmasi Ajukan Perubahan'"
                    size="sm"
                    body-class="p-2"
                    hide-footer
                >
                    <div class="modal-body">
                        <div class="text-center">
                        <div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
                            <i class="icon-warning2"></i>
                        </div>
                        <h6 class="mb-3">Apakah Anda yakin untuk mengajukan perubahan pengkajian untuk pasien 
                        {{rowEdit.ap_fullname || "-"}} ?</h6>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" @click="openPersetujuan = false" class="btn btn-link" data-dismiss="modal">Batal</button>
                        <button type="submit" @click="konfirmasiEdit" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b> Ya, Ajukan</button>
                    </div>
                </b-modal>

            </div>
        </div>

        
        <template v-else>
            <FormDetail v-if="isDetail" :row.sync="row" v-bind="passToSub" />
            <Form v-else :row.sync="row" v-bind="passToSub" />
        </template>
    </div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')
    import Form from './Form.vue'
    import FormDetail from './FormDetail.vue'

    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,FormDetail,Form
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowReg: this.rowReg,
                    triaseLog: this.triaseLog,
                }
            },
        },
        data(){
            return {
                idKey:'aures_id',
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                pasienLama: 0,
                pasienBaru: 0,

                
                belumTriase: 0,
                Esi1: 0,
                Esi3: 0,
                Esi5: 0,
                EsiDoa: 0,

                rowEdit : {},
                rowReg : {},
                triaseLog : [],


                tabDraft : 'N',
                openPersetujuan: false
            }
        },
        mounted(){
            this.filter.status_pasien = 'ALL'
            this.filter.status_periksa = 'ALL'
            this.filter.status_jaminan = 'ALL'
            this.filter.status_rujukan = 'ALL'

            this.filter.is_draft = this.tabDraft
            
            this.doConvertDate()      
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            konfirmasiEdit(){
                this.loadingOverlay=true
                Gen.apiRest(
                    "/do/"+'UGDRekamMedis',
                    {data:{type:'send-request-triase', id: this.rowEdit.aur_aut_id, aut_can_edit_by: this.user.id}}, 
                    "POST"
                ).then(()=>{
                    this.loadingOverlay = false 
                    this.$swal({
                        title: `Permintaan Berhasil Dikirim`,
                        icon: 'success',
                    })
                    
                    if((+this.$route.query.page||1) != 1){
                        this.doResetData()
                    }else{
                        this.apiGet()
                    }
                    this.openPersetujuan = false
                        
                }).catch(err=>{ 
                    this.loadingOverlay = false 
                    this.openPersetujuan = false
                    if(err){
                        err.statusType = err.status
                        err.status = "error"
                        err.message = err.response.data.message
                        err.title = err.response.data.title
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                })
            },
            requestEdit(v){
                this.rowEdit = v
                this.openPersetujuan = true
            },
            to24Hours(date){
                return moment(date).add(48, 'hours').format('YYYY-MM-DD')
            },
            otherConditional(v){
                return (moment().format('YYYY-MM-DD') <= this.to24Hours(v.aut_created_date) || (v.aut_is_approve == 'Y'))
            },
            // :to="{name: $route.name, params: {pageSlug: v.aur_aut_id}, query: {regId: v.aur_id}}"
            toFormTriase(triase_id,regID,pasienID){
                this.loadingOverlay = true
                if(!triase_id){

                    let data = {
                        type : "init-triase",
                        pasien_id: pasienID,
                        regId: regID
                    }
                    Gen.apiRest(
                    "/do/"+'UGDTriase',
                        {data:data}, 
                        "POST"
                    ).then(res=>{
                        this.loadingOverlay = false
                        let id = res.data.id
                        this.$router.push({ name: this.$route.name, params: {pageSlug: id}, query: {regId: regID}})
                        .catch(()=>{})
                    })
                }else{
                    this.loadingOverlay = false
                    this.$router.push({ name: this.$route.name, params: {pageSlug: triase_id}, query: {regId: regID}}).catch(()=>{})
                }
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                console.log(scrollAmount)
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            windowTableScroller(e){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                        const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                        if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                            this.onScrollPoint = true
                            document.documentElement.scroll(0, this.tableScrollPoint)
                            window.addEventListener('wheel', this.tableScrollFunction, false)
                        }
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            changeTab(tab){
                this.tabDraft = tab
                this.filter.is_draft = this.tabDraft
                this.doFill()
            },
              apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },

            toDetail(data){
                this.$router.push({ name: 'UGDTriase', params: { pageSlug: data.aures_id } })
                .catch(()=>{})
            },

            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },100),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                if(this.isList){
                    this.apiGetResume()
                }
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }
    }
</script>